import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const theme = extendTheme({
  fonts: {
    heading: "Briston, sans-serif",
    body: "Roboto, sans-serif",
    american: "Briston",
    wisdom: "Wisdom Script AJ",
    myriad: "MyriadPro Regular",
    italic: "Source Sans Variable Italic",
    brush: "Painted Brush",
    titling: "Titling Normal"
  },
  shadows: {
    outline: 'none',
  },
  styles: {
    global: (props) => ({
      "*, *::before, ::after": {
        wordWrap: "normal"
      },
      body: {
        color: mode("gray.700", "whiteAlpha.900")(props),
        bg: mode("blue.50", "#222")(props),
        fontSize: "1.2em",
        ".deleted": {
          color: "#ff8383 !important",
          fontStyle: "normal !important",
        },
        ".inserted": {
          color: "#b5f4a5 !important",
          fontStyle: "normal !important",
        },
        overflowX: "hidden"
      },
      a: {
        color: mode("black", "black")(props),
        transition: "color 0.15s",
        transitionTimingFunction: "ease-out",
        fontWeight: "500",
        _hover: {
          color: mode("#00262A", "#00262A")(props),
        },
      },
      p: {
        marginBottom: "0.8em"
      },
      ul: {
        marginLeft: "2em",
        marginBottom: "2em"
      },
      h2: {
        fontSize: { base: '2xl', md: '5xl' },
      },
      h3: {
        fontFamily: "Briston",
        fontSize: { base: '2xl', md: '4xl' },
      },
      ".content-block": {
        fontFamily: "Roboto",
        fontSize: "16px",
        strong: {
          fontWeight: "bold",
        },
        h1: {
          fontFamily: "Briston",
          fontWeight: "500",
          fontSize: { base: '48px', md: '55px' },
          lineHeight: "1",
        },
        h2: {
          fontFamily: "Briston",
          fontWeight: "500",
          color: "#5a729a",
          lineHeight: "1",
          fontSize: { base: '2em', md: '48px' },
        },
        h3: {
          fontFamily: "Briston",
          fontWeight: "500",
          color: "#78ac98",
          fontSize: { base: '40px', md: '40px' },
          lineHeight: "1",
          "sub": {
            fontFamily: "Titling Normal",
            fontSize: { base: '20px', md: '20px' },
            color: "#5A729A"
          }
        },
        h4: {
          fontFamily: "Briston",
          fontWeight: "500",
          color: "#5A729A",
          fontSize: { base: '34px', md: '34px' },
          lineHeight: "1.2",
          "sub": {
            fontFamily: "Titling Normal",
            fontSize: { base: '17px', md: '17px' },
            color: "#8989E4"
          }
        },
        h5: {
          fontFamily: "Titling Normal",
          fontWeight: "500",
          color: "#F26624",
          lineHeight: "1.2",
          fontSize: { base: '18px', md: '18px' },
        },
        h6: {
          fontFamily: "Roboto",
          fontWeight: "700",
          color: "#15315A",
          lineHeight: "1.2",
          fontSize: { base: '18px', md: '18px' },
        },
        blockquote: {
          color: "#78AC98",
          fontSize: "20px",
          lineHeight: "1.2",
          borderLeft: "2px solid #F26624",
          paddingLeft: "2em",
          fontFamily: "Titling Normal",
          marginTop: "10px",
          marginBottom: "10px",
        },
        em: {
          fontFamily: "Briston",
          color: "#F26624",
          fontStyle: "normal",
          fontSize: "1.5em",
          fontWeight: "normal",
          lineHeight: "0.65"
        },
        hr: {
          mt: "5px",
          borderTop: "2px solid #16315A",
        },
        a: {
          color: "#1C9A72",
          _hover: {
            color: "#F26624",
          },
        }
      }
    }),
  },
  components: {
    Heading: {
      baseStyle: (props) => ({
        border: "none",
        pb: 2,
        fontWeight: "500",
        a: {
          color: mode("blue", "blue.200")(props),
          _hover: {
            color: mode("blue.600", "blue.300")(props),
          },
        },
      }),
    },
    Text: {
      baseStyle: {
        marginBottom: "0",
      },
    },
    Popover: {
      parts: ['popper'],
      baseStyle: (props) => ({
        popper: {
          zIndex: 10,
          maxW: 'xs',
          // maxW: props.width ? props.width : 'xs',
          w: props.width,
        },
      }),
    },
    Button: {
      baseStyle: {
        fontWeight: "bolder",
        textTransform: 'uppercase',
        fontFamily: 'Titling Wide Standard',
        fontSize: '13px',
        letterSpacing: '1px',
        padding: '12px 24px',
        lineHeight: '1em',
        borderTopStartRadius: 0,
        borderEndStartRadius: 0,
      },
      sizes: {
        sm: {
          fontSize: { base: '10px', md: '12px' },
          px: 4,
          py: 3,
        },
        md: {
          fontSize: { base: '11px', md: '13px' },
          px: 5,
          pt: 4,
          pb: 3,
        },
      },
      variants: {
        outline: {
          bg: 'white',
          color: '#012457',
          boxShadow: '1px 2px #86BA9D',
          _hover: {
            bg: "white",
            color: '#86BA9D',
          },
        },
        solid: {
          bg: '#BEE3DA',
          color: '#012457',
          _hover: {
            bg: "#86BA9D",
            color: '#012457',
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'solid',
      },
    },
    Tag: {
      baseStyle: {
        rounded: "lg",
      },
    },
    textarea: {
      baseStyle: {
        background: "green.300",
      },
    },
    Link: {
      baseStyle: {
        fontWeight: "inherit",
        _hover: {
          textDecoration: "none",
        },
      },
      variants: {
        text: {
          color: "#222",
          transition: "color 0.15s",
          transitionTimingFunction: "ease-out",
          fontWeight: "500",
          _hover: {
            color: "blue.300",
          },
        },
        gradient: {
          bgGradient: "linear(to-br, blue.400,blue.300)",
          bgClip: "text",
          fontWeight: "500",
          _hover: {
            bgGradient: "linear(to-br, blue.500,blue.300)",
            bgClip: "text",
          },
        },
      },
    }
  },
  mdx: {

  },
});

export const getTagColor = type => {
  type = type.toLowerCase()
  if (type === "rails" || type === "ruby") {
    return "red";
  } else if (type === "react") {
    return "cyan";
  } else if (type === "javascript") {
    return "yellow";
  } else if (type === "typescript" || type === "tailwindcss") {
    return "blue";
  } else if (type === "chakraui" || type === "css") {
    return "teal";
  }
};