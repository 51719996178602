import * as React from "react";
import { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  useColorModeValue,
  Container,
  useToast,
  Flex,
  SimpleGrid,
  StackDivider,
  Icon,
  SpaceProps,
  HStack,
  Tag,
  Link,
  Center,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { PageSlideFade } from "../components/page-transitions";
import { apiHost, baseUrl } from "utils/Cons/Constants";
import axios from "axios";
import { Helmet } from "react-helmet";
import headerBg from "../assets/images/header-background.jpg";
import footerBg from "../assets/images/footer-background.jpg";
import cloudsBg from "../assets/images/cloud-background.png";
import parser from "html-react-parser";
import { MotionBox } from "../components/motion";
import MediaBlock from "../components/media-block";
import FavoritesBlock from "../components/favorites-block";
import ImageBlock from "../components/image-block";
import CrowdBlock from "../components/crowd-block";
import MapBlock from "components/map-block";
import CTABlock from "components/cta-block";
import ImagesBlock from "components/images-block";
import ImagesGridBlock from "components/images-grid-block";
import TabsBlock from "components/tabs-block";
import SlidesBlock from "components/slides-block";
import HighlightsBlock from "components/highlights-block";
import EmbedBlock from "components/embed-block";
import ContentBlock from "components/content-block";
import BookBlock from "components/book-block";
import Slider from "react-slick";

const markdownTheme = {
  p: props => {
    const { children } = props;
    return (
      <Text mb={2} fontSize={'0.8em'}>
        {children}
      </Text>
    );
  },
};

const Page = ({ match }) => {
  const toast = useToast();

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(true);

  const settingsSlick = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  let path = match.params[0].replaceAll('/', '|');

  const getPage = () => {
    let data = [];
    axios.get(`${apiHost}/page/get/${path}`)
      .then(res => {
        data = res.data;
        if (res.status == 200) {
          setPage(data);
        }
        setLoading(false);
        return res;
      })
      .catch(err => {
        setLoading(false);
      });

    return data;
  };

  const showToast = (message, status) => {
    toast({
      position: "bottom",
      description: message,
      status: status,
      duration: 5000,
      isClosable: true
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPage();

    console.log(match.params)
  }, []);

  return (
    <PageSlideFade>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.meta_description} />
      </Helmet>
      {!loading && !page.slug && (
        <Box>
          <Container maxW={'5xl'}>
            <Heading
              as={'h3'}
              fontFamily="american"
              fontWeight={500}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
              lineHeight={'100%'}
              textAlign="left"
              mt={{ base: 10, md: 5 }}>
              404 Page Not Found
            </Heading>
            <Box textAlign="left">
              <Button as={Link} href="/">Go to Home Page</Button>
            </Box>
          </Container>
        </Box>
      )}
      {page.slug && (
        <Box>
          {page.header_image_url ? (
            <Box className="home-header-container" backgroundImage={`url(${page.header_image_url})`} backgroundPosition="center center" backgroundSize="cover" minHeight="60dvh">
              <Container maxW={'5xl'}>

              </Container>
            </Box>
          ) : null}
          {page.entry.slides ? (
            <Slider {...settingsSlick} className="page-slider">
              {page.entry.slides?.map((slide, index) => (
                <div>
                  {slide.img != null && (
                    <Link href={slide.link}>
                      <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundRepeat="no-repeat" backgroundSize="cover" minHeight="60dvh">

                      </Box>
                    </Link>
                  )}
                </div>
              ))}
            </Slider>
          ) : null}
          <Box bg="#15315A">
            <Container maxW={'5xl'}>
              <Grid templateColumns='repeat(6, 1fr)'>
                <GridItem colSpan={{ base: 12, md: 6 }}>
                  <Text
                    as="h1"
                    textAlign="left"
                    fontFamily="american"
                    fontWeight={500}
                    fontSize={{ base: '3xl', sm: '4xl', md: '5xl' }}
                    lineHeight={'100%'}
                    color="#BDECDE"
                    mt={{ base: 4, md: 0 }}
                  >{page.title}</Text>
                </GridItem>
              </Grid>
            </Container>
          </Box>
          <Box bg="#BCECDE">
            <Container maxW={'5xl'} pb={10}>
              <Box className="content-block" textAlign="left" bg="white" p={10} roundedBottomRight={20}>
                {page.content_rendered != null ? (
                  <Text textAlign="left">
                    {parser(page.content_rendered)}
                  </Text>
                ) : null}
              </Box>

            </Container>

            {page.fields.content_blocks?.map((block, index) => (
              <Box key={`block-${index}`}>
                {
                  {
                    'anomaly.extension.favorites_block_block': <FavoritesBlock page={page} block={block} />,
                    'anomaly.extension.media_block_block': <MediaBlock page={page} block={block} />,
                    'anomaly.extension.image_block_block': <ImageBlock page={page} block={block} />,
                    'anomaly.extension.crowd_block_block': <CrowdBlock page={page} block={block} />,
                    'anomaly.extension.map_block_block': <MapBlock page={page} block={block} />,
                    'anomaly.extension.cta_block_block': <CTABlock page={page} block={block} />,
                    'anomaly.extension.images_block_block': <ImagesBlock page={page} block={block} />,
                    'anomaly.extension.images_grid_block_block': <ImagesGridBlock page={page} block={block} />,
                    'anomaly.extension.tabs_block_block': <TabsBlock page={page} block={block} />,
                    'anomaly.extension.slides_block_block': <SlidesBlock page={page} block={block} />,
                    'anomaly.extension.highlights_block_block': <HighlightsBlock page={page} block={block} />,
                    'anomaly.extension.embed_block_block': <EmbedBlock page={page} block={block} />,
                    'anomaly.extension.content_block_block': <ContentBlock page={page} block={block} />,
                    'anomaly.extension.book_block_block': <BookBlock page={page} block={block} />,
                  }[block.extension]
                }
              </Box>
            ))}
          </Box>
        </Box>
      )}

    </PageSlideFade >
  );
};

export default Page;
