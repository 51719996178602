import { Box, Container, Flex, Grid, GridItem, Heading, Link, SimpleGrid, Spacer, Text, Image, Button } from "@chakra-ui/react";
import React from "react";
import parser from "html-react-parser";

const FavoritesBlock = ({ page, block }) => {

    let experiences = [];
    let firstImage = {};
    let bgImage = {};
    if (block.entry.experiences) {
        firstImage = block.entry.experiences[0];
        experiences = block.entry.experiences.slice(1);
    }

    if (block.img) {
        bgImage = block.img;
    }

    return (
        <Box backgroundImage={`url(${bgImage})`} backgroundPosition="center center" backgroundSize="cover">
            <Box minHeight="600px" pt={100} pb={300}>
                <Container maxW={'5xl'}>
                    <Box textAlign="left" pb={10}>
                        <div class="experiences-header">
                            {block.entry && block.title != '' ? (
                                <Heading
                                    as={'h2'}
                                    fontSize={{ base: '4xl', sm: '4xl', md: '6xl' }}
                                    textAlign={{ base: 'center', md: 'center' }}
                                    lineHeight={'110%'}
                                    color={block.entry.title_color}>
                                    {block.title}
                                </Heading>
                            ) : null}
                            {block.entry.content ? (
                                <Text className="content-block" textAlign={{ base: 'center', md: 'center' }}>
                                    {parser(block.entry.content)}
                                </Text>
                            ) : null}
                        </div>
                    </Box>

                    <Box bg="#8FBD99" pl={4} pr={4} pt={4} borderTopRadius={20}>
                        <Box bg="#FFFFFF" borderTopRadius={10}>
                            <Grid templateColumns='repeat(6, 1fr)'>
                                <GridItem colSpan={{ base: 6, md: 4 }}>
                                    <Link href={firstImage.link}>
                                        <Box backgroundImage={`url(${firstImage.img})`} backgroundPosition="center center" backgroundSize="cover" height="100%" minHeight="150px" borderTopStartRadius={10} p={10}>
                                            <Flex>
                                                <Spacer />
                                                <Heading
                                                    as={'h2'}
                                                    fontSize={{ base: '3xl', sm: '3xl', md: '5xl' }}
                                                    textAlign={{ base: 'center', md: 'right' }}
                                                    align="right"
                                                    lineHeight="80%"
                                                    w={{ base: "100%", md: "40%" }}
                                                    color="#BDEDDF">
                                                    {firstImage.top_title}
                                                </Heading>
                                            </Flex>

                                        </Box>
                                    </Link>
                                </GridItem>
                                <GridItem colSpan={{ base: 6, md: 2 }}>
                                    <Box bg="white" p={10} textAlign="left" borderTopEndRadius={10}>
                                        <div class="featured-experience">
                                            <Heading
                                                as={'h3'}
                                                fontSize={{ base: '1xl', sm: '1xl', md: '1xl' }}
                                                textAlign="left"
                                                lineHeight="80%"
                                                color="#17325B">
                                                {firstImage.title}
                                            </Heading>
                                            {firstImage.content != null ? (
                                                <Box fontSize="0.9rem">{parser(firstImage.content)}</Box>
                                            ) : null}

                                            <Button as={Link} href={firstImage.link} bg="#F26624" color="white" fontSize="12px" marginTop="20px" letterSpacing="2px" borderTopStartRadius={20} borderBottomStartRadius={20} borderTopEndRadius={20} borderBottomEndRadius={20}>{firstImage.button_text}</Button>
                                        </div>
                                    </Box>
                                </GridItem>
                            </Grid>
                        </Box>
                    </Box>
                    <Box bg="#BDEDDF" p={4} borderBottomRadius={20} minHeight="200px">
                        <Box bg="#FFFFFF" borderBottomRadius={10} minHeight="200px">
                            <Grid templateColumns='repeat(4, 1fr)'>

                                {experiences?.map((slide, index) => (
                                    <GridItem colSpan={{ base: 2, md: 1 }} key={`ex-${index}`}>
                                        {index % 2 == 0 ? (
                                            <Box>
                                                <div class="dark-top">
                                                    <Box textAlign="left" bg="#17325B" height={{ base: "220px", md: "180px" }} overflow="auto" p={5}>
                                                        <Heading
                                                            as={'h3'}
                                                            fontSize={{ base: '1xl', sm: '1xl', md: '2xl' }}
                                                            textAlign="left"
                                                            lineHeight="80%"
                                                            color="#BDEDDF">
                                                            {slide.top_title}
                                                        </Heading>
                                                        <Heading
                                                            as={'h4'}
                                                            fontFamily="Roboto"
                                                            fontWeight="500"
                                                            fontSize={'0.8rem'}
                                                            textAlign="left"
                                                            lineHeight="110%"
                                                            color="#88B597">
                                                            {slide.title}
                                                        </Heading>
                                                        <Button as={Link} href={slide.link} className="more-button" size="small">{slide.button_text}</Button>
                                                    </Box>
                                                </div>
                                                <Link href={slide.link}>
                                                    <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundSize="cover" height={{ base: "220px", md: "180px" }}>

                                                    </Box>
                                                </Link>
                                            </Box>
                                        ) : (
                                                <Box>
                                                    <Link href={slide.link}>
                                                        <Box backgroundImage={`url(${slide.img})`} backgroundPosition="center center" backgroundSize="cover" height={{ base: "220px", md: "180px" }}>

                                                        </Box>
                                                    </Link>
                                                    <div class="light-bottom">
                                                        <Box textAlign="left" bg="#3C5A7E" height={{ base: "220px", md: "180px" }} overflow="auto" p={5}>
                                                            <Heading
                                                                as={'h3'}
                                                                fontSize={{ base: '1xl', sm: '1xl', md: '2xl' }}
                                                                textAlign="left"
                                                                lineHeight="80%"
                                                                color="#BDEDDF">
                                                                {slide.top_title}
                                                            </Heading>
                                                            <Heading
                                                                as={'h4'}
                                                                fontFamily="Roboto"
                                                                fontWeight="500"
                                                                fontSize={'0.8rem'}
                                                                textAlign="left"
                                                                lineHeight="110%"
                                                                color="#88B597">
                                                                {slide.title}
                                                            </Heading>

                                                            <Button as={Link} href={slide.link} className="more-button" size="small">{slide.button_text}</Button>
                                                        </Box>
                                                    </div>
                                                </Box>
                                            )}
                                    </GridItem>
                                ))}

                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
};

export default FavoritesBlock;